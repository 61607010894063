import antdData from 'antd/lib/locale-provider/en_US';

const messages = {
  // Common use
  'common.name': 'Name',
  'common.name.inputMessage': 'Please input your name!',
  'common.lastName': 'Last Name',
  'common.lastName.inputMessage': 'Please input your last name!',
  'common.email': 'Email',
  'common.email.invalidMessage': 'The input is not valid e-mail!',
  'common.email.inputMessage': 'Please input your e-mail!',
  'common.mobileNo': 'Mobile No',
  'common.mobileNo.inputMessage': 'Please input your mobile number!',
  'common.mobileNo.invalidMessage': 'The input is not valid mobile number!',
  'common.description': 'Description',
  'common.description.inputMessage': 'Please input some description!',
  'common.role': 'Role',
  'common.role.placeholder': 'Please select your role(s)',
  'common.role.inputMessage': 'Please select a role!',
  'common.id': 'ID',
  'common.id.inputMessage': 'Please input ID!',
  'common.action': 'Action',
  'common.delete': 'Delete',
  'common.yes': 'Yes',
  'common.no': 'No',
  'common.step': 'Step',
  'common.next': 'Next',
  'common.previous': 'Previous',
  'common.done': 'Done',
  'common.day': 'Day',
  'comon.send': 'Send',
  'comon.sendTo': 'Send to',
  'common.date': 'Date',
  'common.month': 'Month',
  'common.from': 'From Month',

  // Menu
  'menu.home': 'Home',
  'menu.dashboard': 'Dashboard',
  'menu.products': 'Product',
  'menu.addProduct': 'Add Product',
  'menu.projects': 'Project',
  'menu.addProjects': 'Add Project',
  'menu.policies': 'User',
  'menu.addPolicy': 'Add User',
  'menu.roles': 'Role',
  'menu.sales': 'Sale',
  'menu.addSale': 'Add Sale',
  'menu.undefined': ' ',
  'menu.applications': 'Application',
  'menu.addApplication': 'Add Application',
  'menu.loans': 'Loan',
  'menu.withdraws': 'Withdraw',
  'menu.imports': 'Import',
  'menu.importstab': 'ImportsTab',
  'menu.setting': 'Setting',
  'menu.adj': 'Cutoff Statement',
  'menu.acc': 'Cutoff account',
  'menu.statementCsv': 'Print Statement CSV',
  'menu.statementPdf': 'Print Statement PDF',
  'menu.importApplications': 'Application',
  'menu.importPayments': 'Payment',
  'menu.reports': 'Report',
  'menu.sms': 'Sms',
  'menu.importCreditreports': 'Credit Report',
  'menu.requestapprovals': 'Request Approval',
  'menu.dashboards': 'Dashboard',
  'menu.importDisbursements': 'Disbursement',
  'menu.sms.history': 'SMS History',
  'menu.sms.loanClose': 'SMS to notify loan close',
  'menu.sms.statement': 'SMS to notify payment',
  'menu.sms.waitConfirm': 'SMS to notify applications status',
  'menu.sms.estatement': 'Send E-statement',
  'menu.sms.withdraw': 'SMS to notify withdraw status',
  'menu.importCollectorLoans': 'Collector Loans',
  'menu.importWriteoff': 'Write Off Loans',

  // Dashboards
  'dashboards.rangetime': 'Date Range',
  'dashboards.all': 'All',
  'dashboards.confirm': 'Confirm',
  'dashboards.waitConfirm': 'Wait Confirm',
  'dashboards.reject': 'Reject',
  'dashboards.approvalRate': 'Approval rate',

  // Form
  'form.header.policies': 'Policies Information',
  'form.header.products': 'Products Information',
  'form.header.roles': 'Roles Information',
  'form.header.sales': 'Sales Information',
  'form.header.projects': 'Projects Information',
  'form.login': 'Login',
  'form.register': 'Register',
  'form.password': 'Password',
  'form.password.inputMessage': 'Please input your password!',
  'form.add': 'Add',
  'form.save': 'Save',
  'form.cancel': 'Cancel',
  'form.reject': 'Reject',
  'form.submit': 'Submit',
  'form.header.adj': 'Cutoff Statement',
  'form.header.acc': 'Cutoff Account',
  'form.header.statement': 'Print Statement',
  'form.header.report': 'Report',

  // Currency unit
  'currency.thaiBaht': 'Baht',

  // Account Type
  'account.accountNo': 'Account Number',
  'account.accountNo.inputMessage': 'Please input your account number!',
  'account.accountNo.invalidMessage': 'The input is not valid account number!',
  'account.accountName': 'Account Name',
  'account.accountName.inputMessage': 'Please input your account name!',
  'account.accountType': 'Account Type',
  'account.accountType.placeholder': 'Please select an account type',
  'account.accountType.inputMessage': 'Please select your account type!',
  'account.saving': 'Saving Account',
  'account.current': 'Current Account',
  'account.fixed deposit': 'Fixed Deposit Account',

  // Formatted use in Ncb
  'ncb.ncb.ncbButton': 'Check NCB',
  'ncb.ncb.lastDate': 'Last Check Date',
  'ncb.ncb.paymentHistoryHeader': 'Payment History (12 months)',
  'ncb.ncb.realPaymentHistoryHeader': 'payment history',
  'ncb.ncb.overDueBalance6monthHeader': 'Overdue Owed 6 month',
  'ncb.ncb.outstandingBalanceHeader': 'Outstanding Balance',
  'ncb.ncb.minimumPaymentHeader': 'Minimum Payment',
  'ncb.ncb.openDateHeader': 'Open Date',
  'ncb.ncb.lastPaymentDateHeader': 'Last Payment Date',
  'ncb.ncb.loansHeader': 'Loan',
  'ncb.ncb.loanAccount': 'account',
  'ncb.ncb.openLoanTitle': 'Opened',
  'ncb.ncb.closeLoanTitle': 'Closed',
  'ncb.ncb.kindLoanTitle': 'Kind load',
  'ncb.ncb.loanWithoutCarAndHouseTitle': 'Loan without car and house',
  'ncb.ncb.enquiryHistory3monthTitle': 'Enquiry history last 3 month',
  'ncb.ncb.newApplicationTitle': 'New Application',
  'ncb.ncb.reviewCreditTitle': 'Review Credit',
  'ncb.ncb.institution': 'institution',
  'ncb.ncb.amountMinimumPaymentTitle': 'Amount of minimum payment',
  'ncb.ncb.currency': 'baht',
  'ncb.ncb.case': 'case',

  // Formatted use in Profile
  'ncb.profile.profileHeader': 'Personal Profile',
  'ncb.profile.nameTitle': 'Firstname-Lastname',
  'ncb.profile.letterOfConsentTitle': 'Letter of Consent',
  'ncb.profile.letterOfConsentStatusTitle': `Letter of Consent's Status`,
  'ncb.profile.letterOfConsentStatusundefined': 'No Data',
  'ncb.profile.letterOfConsentStatusY': 'Yes',
  'ncb.profile.letterOfConsentStatusN': 'No',
  'ncb.profile.letterOfConsentStatusC': 'Cancelled',
  'ncb.profile.identificationNumberTitle': 'Identification Number',
  'ncb.profile.idTitleundefined': 'Unscpecified',
  'ncb.profile.idTitle0': 'Unscpecified',
  'ncb.profile.idTitle1': 'Citizen ID',
  'ncb.profile.idTitle2': 'Civil Servant ID',
  'ncb.profile.idTitle3': 'Enterprise',
  'ncb.profile.idTitle4': 'Trade No.',
  'ncb.profile.idTitle5': 'Alien',
  'ncb.profile.idTitle6': 'Thai passport',
  'ncb.profile.idTitle7': 'Foreign Passport/ID',
  'ncb.profile.idTitle9': 'Other',
  'ncb.profile.personalInformationTitle': 'Personal Information',
  'ncb.profile.dateOfBirthTitle': 'Date of Birth',
  'ncb.profile.nationalityTitle': 'Nationality',
  'ncb.profile.nationalityundefined': 'No Data',
  'ncb.profile.nationality0': 'Unspecified',
  'ncb.profile.nationality1': 'Thai',
  'ncb.profile.nationality2': 'Chinese',
  'ncb.profile.nationality3': 'Japanese',
  'ncb.profile.nationality4': 'American',
  'ncb.profile.nationality99': 'Other',
  'ncb.profile.genderTitle': 'Gender',
  'ncb.profile.genderundefined': 'No Data',
  'ncb.profile.gender1': 'Female',
  'ncb.profile.gender2': 'Male',
  'ncb.profile.maritalTitle': 'Marital Status',
  'ncb.profile.maritalStatusundefined': 'No data',
  'ncb.profile.maritalStatus0': 'Unspecified',
  'ncb.profile.maritalStatus1': 'Married',
  'ncb.profile.maritalStatus2': 'Single',
  'ncb.profile.maritalStatus3': 'Widowed',
  'ncb.profile.maritalStatus4': 'Divorced',
  'ncb.profile.numberOfChildrenTitle': 'Number of Children',
  'ncb.profile.occupationTitle': 'Occupation',
  'ncb.profile.occupationundefined': 'No Data',
  'ncb.profile.occupation0': 'Unspecified',
  'ncb.profile.occupation1': 'Employee',
  'ncb.profile.occupation2': 'Employee of State Enterprise',
  'ncb.profile.occupation3': 'Civil Servant',
  'ncb.profile.occupation4': 'Self-employed',
  'ncb.profile.occupation5': 'Professional',
  'ncb.profile.occupation6': 'Commission earn',

  // Formatted use in Address
  'ncb.address.addressHeader': 'Address Information',
  'ncb.address.tableColumnAddress': 'Address',
  'ncb.address.tableColumnAddressType': 'Type',
  'ncb.address.tableColumnAddressStatus': 'Status',
  'ncb.address.tableColumnDate': 'Reported Date',
  'ncb.address.addressTypeundefined': ' ',
  'ncb.address.addressType0': 'Undefined',
  'ncb.address.addressType1': 'Home Registration',
  'ncb.address.addressType2': 'Mailing Address',
  'ncb.address.addressType3': 'ID Registration',
  'ncb.address.addressType4': 'Office Address',
  'ncb.address.residentialStatusundefined': ' ',
  'ncb.address.residentialStatus0': 'Unspecified',
  'ncb.address.residentialStatusP': 'Living with relation',
  'ncb.address.residentialStatusH': 'Owns',
  'ncb.address.residentialStatusR': 'Rents',
  'ncb.address.residentialStatusC': 'Company Provided',
  'ncb.address.residentialStatusF': 'Owns - Mortgage',

  // Formatted use in Account, AccountRenderer and PaymentHistory
  'ncb.account.accountDescriptionHeader': 'Account Description',
  'ncb.account.lenderTitle': 'Lender',
  'ncb.account.memberTypeTitle': "Member's type",
  'ncb.account.informationDate': 'Information date',
  'ncb.account.accountNumber': 'Account Number',
  'ncb.account.loanTypeTitle': 'Loans Type',
  'ncb.account.accountOpenedDate': 'Account Opened Date',
  'ncb.account.debtRestructuringDate': 'Debt restructuring Date',
  'ncb.account.accountClosedDate': 'Account Closed Date',
  'ncb.account.creditCardTypeTitle': "Credit card's type",
  'ncb.account.creditCardType1': 'VISA',
  'ncb.account.creditCardType2': 'DC (Diner Club)',
  'ncb.account.creditCardType3': 'MC (Master Card)',
  'ncb.account.creditCardType4': 'AMEX (Charged Card)',
  'ncb.account.creditCardType5': 'AMEX (Credit Card)',
  'ncb.account.creditCardType6': 'JCB',
  'ncb.account.creditCardType13': 'VSMC (VISA/MC)',
  'ncb.account.creditCardType15': 'VSJC (VISA,JCB)',
  'ncb.account.creditCardType20': 'LBCB (Local Bank Credit Card)',
  'ncb.account.creditCardType21': 'VSL (VISA/Local Bank Credit Card)',
  'ncb.account.creditCardType23': 'MSL (MC/Local Bank Credit Card)',
  'ncb.account.creditCardType25': 'JSL (JCB/Local Bank Credit Card)',
  'ncb.account.creditCardType26': 'AMEX BBL',
  'ncb.account.creditCardType35': 'MCJC (MC/JCB)',
  'ncb.account.creditCardType41': 'VML (VISA/MC/LBCD)',
  'ncb.account.creditCardType42': 'VJL (VISA/JCB/LBCD)',
  'ncb.account.creditCardType43': 'MJL (MC/JCB/LBCD)',
  'ncb.account.creditCardType44': 'VMJ (VISA/MC/JCB)',
  'ncb.account.creditCardType60': 'VMJL (VISA/MC/JCB/LBCD)',
  'ncb.account.creditCardType65': 'CCC (Central Card)',
  'ncb.account.creditCardType66': 'Power Buy Card',
  'ncb.account.creditCardType67': 'First Choice Card',
  'ncb.account.creditCardType68': 'Department Store Card',
  'ncb.account.creditCardType69': 'Other Private Label Card',
  'ncb.account.creditCardType99': 'Other',
  'ncb.account.creditCardTypeundefined': 'No data',
  'ncb.account.ownershipIndicatorTitle': 'Type of Credit Account',
  'ncb.account.ownershipIndicatorundefined': 'No Data',
  'ncb.account.ownershipIndicator1': 'Individual',
  'ncb.account.ownershipIndicator2': 'Supplementary Card',
  'ncb.account.ownershipIndicator4': 'Joint',
  'ncb.account.ownershipIndicatorA': 'DSPT Individual',
  'ncb.account.ownershipIndicatorB': 'DSPT Supplementary Card',
  'ncb.account.ownershipIndicatorC': 'Remark Closed Account at AMC',
  'ncb.account.ownershipIndicatorD': 'DSPT Joint',
  'ncb.account.ownershipIndicatorF': 'INVS Individual',
  'ncb.account.ownershipIndicatorG': 'INVS Supplementary Card',
  'ncb.account.ownershipIndicatorH': 'INVS Authorized',
  'ncb.account.ownershipIndicatorI': 'INVS Joint',
  'ncb.account.numberOfCoBorrowersTitle': 'Number of Co-borrowers',
  'ncb.account.creditLimitTitle': 'Credit Limit/Original Loan Amount',
  'ncb.account.installmentAmountTitle': 'Installment Amount',
  'ncb.account.installmentNumberOfPaymentTitle': 'Installment Number of Payment',
  'ncb.account.installmentFrequencyundefined': ' ',
  'ncb.account.installmentFrequency0': 'Unspecified',
  'ncb.account.installmentFrequency1': 'Weekly',
  'ncb.account.installmentFrequency2': 'Biweekly',
  'ncb.account.installmentFrequency3': 'Monthly',
  'ncb.account.installmentFrequency4': 'Bimonthly',
  'ncb.account.installmentFrequency5': 'Quarterly',
  'ncb.account.installmentFrequency6': 'Semimonthly',
  'ncb.account.installmentFrequency7': 'Special use',
  'ncb.account.installmentFrequency8': 'Semi-yearly',
  'ncb.account.installmentFrequency9': 'Yearly',
  'ncb.account.minimumInstallmentTitle': 'Minimum Installment(%)',
  'ncb.account.unitMakeTitle': 'Unit Make',
  'ncb.account.unitModelTitle': 'Unit Model',
  'ncb.account.creditTypeFlagTitle': 'Credit Type Flag',
  'ncb.account.outstandingBalanceTitle': 'Outstanding Balance',
  'ncb.account.overdueBalanceTitle': 'Overdue Balance',
  'ncb.account.dateOfLastPaymentTitle': 'Date of Last payment',
  'ncb.account.defaultDateTitle': 'Default date',
  'ncb.account.accountStatusHeader': 'Status',
  'ncb.account.objectiveHeader': 'Objective',
  'ncb.account.objecttiveTitle': 'Objective',
  'ncb.account.paymentHistoryHeader': 'Payment history',
  'ncb.account.month': 'month',
  'ncb.account.tableColumnPaymentType': 'Payment type',
  'ncb.account.overdueBalanceInformationHeader': 'OverdueBalance Information(36month)',

  // Forrmatted message use in Enquiry
  'ncb.enquiryHistory.enquiryHistoryHeader': 'Enquiry History',
  'ncb.enquiryHistory.tableColumnDate': 'Date/Time',
  'ncb.enquiryHistory.tableColumnLender': 'Lender',
  'ncb.enquiryHistory.tableColumnEnquiryObjective': 'Enquiry Objective',
  'ncb.enquiryHistory.searchObjective1': '01:New Application',
  'ncb.enquiryHistory.searchObjective2': '02:Review Credit',

  // Formatted message use more than 1 page
  'ncb.common.accountStatusTitle': 'Account Status',
  'ncb.common.accountHeader': 'Account Information',
  'ncb.common.noData': 'No data',
  'ncb.common.loanTypeundefined': 'No data',
  'ncb.common.loanType1': 'Commertcial Loan',
  'ncb.common.loanType4': 'Overdraft',
  'ncb.common.loanType5': 'Personal Loan',
  'ncb.common.loanType6': 'Housing/Mortgage',
  'ncb.common.loanType7': 'Securities/Margin Loan',
  'ncb.common.loanType8': 'Domestic Bill',
  'ncb.common.loanType9': 'Clean Bill Discounted (CBD)',
  'ncb.common.loanType10': 'Import Bill',
  'ncb.common.loanType11': 'Export Bill',
  'ncb.common.loanType12': 'Trust Receipt (T/R)',
  'ncb.common.loanType13': 'Other Bills',
  'ncb.common.loanType14': 'Pre-Shipment Financing/Packing Credit',
  'ncb.common.loanType15': 'Claim Paid - Aval',
  'ncb.common.loanType16': 'Claim Paid - Acceptance',
  'ncb.common.loanType17': 'Claim Paid - L/C',
  'ncb.common.loanType18': 'Claim Paid - L/G',
  'ncb.common.loanType19': 'Other Claim Paid',
  'ncb.common.loanType20': 'Automobile Leasing',
  'ncb.common.loanType21': 'Other Hire Purchase',
  'ncb.common.loanType22': 'Credit Card',
  'ncb.common.loanType27': 'Automobile Hire Purchase',
  'ncb.common.loanType28': 'Line of Credit',
  'ncb.common.loanType29': 'Other Leasing',
  'ncb.common.loanType31': 'Hire-purchase for Agriculture with vary installment payment',
  'ncb.common.loanType32': 'Hire-purchase for Agriculture',
  'ncb.common.loanType33': 'Loan for agriculture',
  'ncb.common.loanType34': 'Factoring',
  'ncb.common.loanType35': 'Futures Contract',
  'ncb.common.loanType36': 'Coop Loan',
  'ncb.common.loanType37': 'Nano-Finance',
  'ncb.common.loanType50': 'Securitized Commercial Loan',
  'ncb.common.loanType51': 'Securitized Personal Loan',
  'ncb.common.loanType52': 'Securitized Housing/Mortgage',
  'ncb.common.loanType53': 'Securitized Automobile Leasing',
  'ncb.common.loanType54': 'Securitized Other Hire Purchase',
  'ncb.common.loanType55': 'Securitized Credit Card',
  'ncb.common.loanType56': 'Securitized Automobile Hire Purchase',
  'ncb.common.loanType57': 'Securitized Other Leasing',
  'ncb.common.loanType58': 'Securitized Overdraft',
  'ncb.common.loanType90': 'Restructured Debt',
  'ncb.common.loanType99': 'Other Loans',
  'ncb.common.accountStatusundefined': 'No Data',
  'ncb.common.accountStatus10': 'Normal',
  'ncb.common.accountStatus11': 'Closed account',
  'ncb.common.accountStatus12': "Debt Moratorium as member's policy",
  'ncb.common.accountStatus13': "Debt Moratorium as Government's policy",
  'ncb.common.accountStatus14': "Debt Moratorium for farmers as the Government's policy",
  'ncb.common.accountStatus20': 'Past due over 90 days',
  'ncb.common.accountStatus30': 'Under litigation',
  'ncb.common.accountStatus31': 'Under the process of payment as agreed upon un the courts of law',
  'ncb.common.accountStatus32': 'Case dismissed due to lapse of period of prescription',
  'ncb.common.accountStatus33': 'Write off account',
  'ncb.common.accountStatus40': 'Account closed with on-going payment',
  'ncb.common.accountStatus41': 'Investigation requested by a/c owner',
  'ncb.common.accountStatus42': 'Debt transferred or sold',
  'ncb.common.accountStatus43': 'Debt tranferred/sold and settled',

  // Loan options
  'loan.status': 'Status',
  'loan.effectiveDate': 'Effective Date',
  'loan.approveDate': 'Approve Date',
  'loan.openDate': 'Open Date',
  'loan.lastpayDate': 'Last Payment Date',
  'loan.lastpayAmount': 'Last Payment Amount',

  // Roles
  'roles.name': 'Name',
  'roles.name.inputMessage': 'Please input your role name!',
  'roles.scopes': 'Scopes',
  'roles.scopes.placeholder': 'Please select scope',
  'roles.scopes.inputMessage': 'Please select at least 1 scope!',

  // Sales
  'sales.id': 'Id',
  'sales.code': 'Code',
  'sales.code.inputMessage': 'Please input your code!',
  'sales.teamLead': 'TeamLead',
  'sales.teamLead.checkBox': 'I am team lead',
  'sales.teamLead.select.placeholder': 'Please select a team lead',
  'sales.teamLead.inputMessage': 'Please select your team lead!',

  // Projects
  'projects.id': 'Id',
  'projects.name': 'Name',

  // Questionare
  'question.areYouSure': 'Are you sure?',

  'documents.uploadHit': 'Click or drag file to this area to upload',
  'documents.fileNameHeader': 'File name',
  'documents.descriptionHeader': 'Description',
  'documents.descriptionMessage': `Please input the file's description!`,
  'documents.categoryHeader': 'Category',
  'documents.categoryMessage': `Please input the file's category!`,
  'documents.application': 'Application',
  'documents.citizenCard': 'Citizen Card',
  'documents.incomeStatement': 'Income Statement',
  'documents.consent': 'Consent',
  'documents.letterOfGuarantee': 'Letter of Guarantee',
  'documents.increaseCreditLimitDocuments': 'Increase Credit Limit Documents',
  'documents.dissurseDocuments': 'Dissurse Documents',
  'documents.bankbook': 'Bank Book',
  'documents.signature': 'Signature',
  'documents.other': 'Other',
  'documents.submit': 'Submit',

  'requireMessage.select': 'Field {field} is required',
  'requireMessage.fill': 'Field {field} is required',
  'requireMessage.maritalStatus': 'Field Marital Status is required',
  'requireMessage.statementDate': 'Field Statement Date is required',
  'requireMessage.dueDate': 'Field Due Date is required',
  'requireMessage.bankName': 'Field Bank is required',
  'requireMessage.accountNo': 'Field Account No is required',
  'requireMessage.statementAddress': 'Field Statement Address is required',
  'requireMessage.analystAmount': 'Field analystAmount is required',

  // invite user
  'inviteUser.userInformation': 'User Information',

  // title
  'education.title': 'education / work / income',
  // report
  'report.name': 'Report Name',
  // withdraw
  'withdraw.tab.Pending': 'Pending',
  'withdraw.tab.Downloaded': 'Downloaded',
  'withdraw.tab.Transferred': 'Transferred',
  'withdraw.tab.Cancelled': 'Cancelled',
  'withdraw.column.id': 'ID',
  'withdraw.column.loanId': 'Loan Id',
  'withdraw.column.name': 'Name',
  'withdraw.column.productName': 'Product',
  'withdraw.column.amount': 'Amount',
  'withdraw.column.bankName': 'Bank Name',
  'withdraw.column.accountNo': 'Account No',
  'withdraw.column.transferDate': 'Transfer Date',
  'withdraw.column.effectiveDate': 'Effective Date',
  'withdraw.column.status': 'Status',
  'withdraw.column.createDate': 'Create Date',
  'withdraw.button.downloadAll': 'Download All',
  'withdraw.button.download': 'Download',
  'withdraw.button.transfer': 'Transfer',
  'withdraw.button.cancel': 'Cancel',
  'withdraw.button.reset': 'Reset Status',
  'withdraw.button.transfer.cimb': 'Transfer CIMB',

  // disbursements
  'disbursements.name': 'Name',
  'disbursements.accountNo': 'Account No',
  'disbursements.amount': 'Amount',
  'disbursements.status': 'Status',
  'disbursements.transferredDate': 'Transfer Date',
  'disbursements.rejectDesc': 'Reject Description',

  // import
  'import.applications': 'Import Applications',
  'import.payments': 'Import Payments',
  'import.requestAmount': 'Request Amount',
  'import.applications.summary.all': 'All',
  'import.applications.summary.normal': 'Ready',
  'import.applications.summary.duplicate': 'Duplicate',
  'import.applications.summary.applications': 'app',
  'import.column.status': 'status',
  'import.payments.summary.all': 'All',
  'import.payments.summary.normal': 'Ready',
  'import.payments.summary.notupdate': 'Not Update',
  'import.payments.summary.notfound': 'Not Found',
  'import.payments.summary.duplicate': 'Duplicate',
  'import.payments.summary.close': 'Close',
  'import.payments.summary.account': 'account',
  'import.payments.loanId': 'Loan Id',
  'import.payments.name': 'Name',
  'import.payments.transdate': 'Trans Date',
  'import.payments.code': 'Code',
  'import.payments.bankCode': 'Bank Code',
  'import.payments.amount': 'Amount',
  'import.payments.status': 'Status',
  'import.applications.confirmImport': 'Confirm Import',
  'import.payments.confirmImport': 'Confirm Import',
  'import.payments.summary.lesszero': 'Less Zero',
  'import.creditreports': 'Import Creditreports',
  'import.creditreports.loanCount': 'Loan Count',
  'import.creditreports.osb': 'Outstanding Balance',
  'import.creditreports.confirmImport': 'Confirm Import',
  'import.disbursements': 'Import Disbursements',
  'import.disbursements.summary.all': 'all',
  'import.disbursements.summary.normal': 'normal',
  'import.disbursements.summary.duplicate': 'duplicate',
  'import.disbursements.summary.notfound': 'notfound',
  'import.disbursements.summary.fail': 'fail',
  'import.disbursements.summary.list': 'list',
  'import.disbursements.summary.amountSubmit': 'amount submit',
  'import.disbursements.summary.amountReject': 'amount reject',
  'import.disbursements.summary.amountFail': 'amount fail',
  'import.disbursements.summary.baht': 'baht',
  'import.disbursements.confirmImport': 'Confirm Import',
  'import.collectorLoans': 'Import Collector Loans',
  'import.collectorLoans.summary.all': 'all',
  'import.collectorLoans.summary.normal': 'normal',
  'import.collectorLoans.summary.duplicate': 'duplicate',
  'import.collectorLoans.summary.notfound': 'notfound',
  'import.collectorLoans.summary.missing': 'missing',
  'import.collectorLoans.summary.list': 'list',
  'import.collectorLoans.period': 'period',
  'import.collectorLoans.loanId': 'loanId',
  'import.collectorLoans.collector': 'collector',
  'import.collectorLoans.fromDate': 'fromDate',
  'import.importWriteoff': 'Import Writeoff',
  'import.importWriteoff.summary.writeoff': 'already writeoff',
  'import.importWriteoff.summary.notLatest': 'not latest',
  'import.importWriteoff.writeoffDate': 'writeoff date',
  'import.importWriteoff.writeoffAmount': 'writeoff amount',

  // Note
  note: 'Note',
  'note.name': 'Name',
  'note.mobileNo': 'MobileNo',
  'note.createDate': 'Create Date',
  'note.category': 'Category',
  'note.followupDate': 'Followup Date',
  'note.channel': 'Channel',
  'note.priority': 'Priority',
  'note.repayDate': 'Repay Date',
  'note.followupAmount': 'Followup Amount',
  'note.repayAmount': 'Repay Amount',
  'note.status': 'Status',
  'note.collectionStatus': 'Collection Status',
  'note.message': 'Message',
  // requestApproval
  'requestApproval.tab.appId': 'Application Id',
  'requestApproval.tab.type': 'Type',
  'requestApproval.button.approve': 'Approve',
  'requestApproval.button.reject': 'Reject',

  // Field label
  id: 'Id',

  // Ui label
  action: 'Action',
  ok: 'Ok',
  search: 'Search',

  startDate: 'Start Date',
  endDate: 'End Date',
  withdraw: 'Withdraw',
  filing: 'Filing',
  restructure: 'Restructure',
  writeOff: 'Writeoff',
  writeOffDate: 'Writeoff Date',
  amortizeTable: 'Amortize Table',
  contract: 'Contract',
  closeContract: 'Close Contract',
  firstDueDate: 'First Due Date',
  firstDisburseDate: 'First Disburse Date',
  firstStatementDate: 'First Statement Date',
  requestapprovals: 'Request Approvals',
  payment: 'Payment',
  paymentDate: 'Payment Date',
  paymentChannel: 'Payment Channel',
  amount: 'Amount',
  qrcode: 'QR Code',
  'search here': 'Search Here',

  'sms.type': 'Type',
  'sms.type.type1': 'แจ้งเตือนชำระหนี้',
  'sms.type.type2': 'ส่ง E-Statement',
  'sms.cutof': 'Cut of date',
  'sms.estatement': 'E-Statement',
  'sms.estatement1': 'All',
  'sms.estatement2': 'Yes',
  'sms.estatement3': 'No',
  'sms.format': 'Format',
  'sms.format.format1': 'แจ้งเตือนชำระหนี้หลังวันตัดรอบ',
  'sms.format.format2': 'แจ้งเตือนชำระหนี้ก่อนตก Bucket',
  'sms.format.format3': 'ข้อความอื่นๆ',
  'sms.browse': 'Browse',
  'sms.send': 'Send',
  'sms.page-title': 'SMS',
  'sms.helmet-title': 'SMS History',
  'sms.filter.mobileNo': 'MobileNo',
  'sms.filter.startDate': 'StartDate',
  'sms.filter.endDate': 'EndDate',
  'sms.projectId': 'ProjectId',
  'sms.mobileNo': 'MobileNo',
  'sms.messageId': 'MessageId',
  'sms.message': 'Message',
  'sms.status': 'Status',
  'sms.createBy': 'CreateBy',
  'sms.success-search': 'search success',
  'sms-loan-close.helmet-title': 'Sms Loan Close',
  'sms-loan-close.page-title': 'SMS to notify loan close',
  'sms-statement.page-title': 'SMS to notify payment',
  'sms-estatement.page-title': 'Send E-statement',
  'sms-application-waitconfirm.page-title': 'SMS to notify applications status',
  'sms-withdraw.page-title': 'SMS to notify withdraws status',

  // UI Text
  // Used when form validation fail
  'common.inputvalidation-failed': 'You have not provide input correctly',
  'common.please-check': 'Please check',
  'common.are-you-sure': 'Delete this item? This cannot be undone.',
  'common.dropzoneUpload': 'Click or drag file here',

  // Login Page
  'login.helmet-title': 'Login',
  'login.please-log-in': 'Please Log in',
  'login.remember-me': 'Remember me',
  'login.forgot-password': 'Forgot password?',
  'login.login': 'Login',
  'login.register': 'Register',
  'login.if-you-dont-have-account': "If you don't have account",
  'login.success-message': 'Logged In',
  'login.success-description': 'You have successfully logged in to Noburo CBS!',
  'login.failed-message': 'Login Failed',
  // Register page
  'register.helmet-title': 'Register',
  'register.please-register': 'Please Register',
  'register.confirm-password': 'Confirm Password',
  'register.register': 'Register',
  'register.login': 'Login',
  'register.if-you-already-have-account': 'If you already have account',
  'register.success-message': 'Registered',
  'register.success-description': 'You have successfully registered. Please check your email',
  'register.failed-message': 'Register Failed',
  // Thank you page
  'thankyou.thankyou': 'Thank you for register with Noburo Cbs',
  'thankyou.please-check-email-to-verify-your-account':
    'Please check your e-mail to verify your account.',
  // Verify page
  'verify.success-message': 'Email Verified',
  'verify.success-description': 'You have successfully verified your email',
  // Forgot password
  'forgot.helmet-title': 'Forgot Password',
  'forgot.page-title': 'Forgot Password?',
  'forgot.instruction':
    'Forgotten your password? Please enter your email address below. You will receive a link to set a new password via email.',
  'forgot.success-message': 'We have sent a reset password link to your email',
  'forgot.back-to-login': 'Back to login',
  // Reset password
  'reset.helmet-title': 'Reset password',
  'reset.page-title': 'Reset password',
  'reset.success-message': 'Reset Password Success',

  // Onboarding
  'onboarding.step': 'Step',
  'onboarding.company-information': 'Company Information',
  'onboarding.admin-information': 'Admin Information',
  'onboarding.verify-otp': 'Verify Otp',
  'onboarding.success-message': 'Onboarding Success',
  'onboarding.success-description': 'You have successfully onboarding to Noburo Cbs',
  'onboarding.failed-message': 'Please fill in required information',
  // Accept Invite
  'accept-invite.user-info': 'User Information',
  'accept-invite.confirm': 'Confirm',

  // Application List
  'application-list.helmet-title': 'Application',
  'application-list.page-title': 'Application',
  'application-list.search-fields': 'Search from applicationId, firstName, lastName or citzenId',

  // New Application
  'application-new.helmet-title': 'Add Application',
  'application-new.page-title': 'Add Application',
  'application-new.success-message': 'Add Application Success',

  // Edit Application
  'application-edit.helmet-title': 'Application | {id}',
  'application-edit.page-title': 'Application',
  'application-edit.save-success-message': 'Update Application Succeeded',
  'application-edit.update-status-success-message': "Update Appplication's Status Succeeded",
  'application-edit.send-for-reprocessing':
    'Do you want to send this application for reprocessing?',
  'application-edit.tabname.application': 'Application',
  'application-edit.tabname.document': 'Document',
  // DebtSummary
  debtSummary: 'Debt Summary',
  'debtSummary.totalCreditLimit': 'Total Credit Limit(baht)',
  'debtSummary.totalOsb': 'Total Outstanding Balance(baht)',
  'debtSummary.osbDivineSalary': 'Total Outstanding Balance / Base Salary',
  'debtSummary.osbDivineIncome': 'Total Outstanding Balance / Base Salary + ExtraIncome',
  'debtSummary.overdue': 'Overdue',
  'debtSummary.timeFromLastOpenLoan': 'time from last open loan (days)',

  'loan-list.helmet-title': 'Loan',
  'loan-list.page-title': 'Loan',
  'loan-list.search-fields': 'Search from loanId, firstName, lastName or citzenId',

  'loan-edit.helmet-title': 'Loan | {id}',
  'loan-edit.page-title': 'Loan',
  'loan-edit.delete-transaction': 'Delete Transaction',
  'loan-edit.show-delete-transaction': 'Show Deleted Transactions',
  'update-loan.success-message': 'Update Loan Success',
  'editloan.success-message': 'Send Edit Loan Request Success. Please wait for Approval',
  'download-amortize-table.success-message': 'Download Amortize Table Success',
  'download-contract.success-message': 'Download Contract Success',
  'download-close-contract.sucess-message': 'Download Cloae Contract Success',
  'download-statement.success-message': 'Download Statement Success',
  'download-lawsuit.success-message': 'Download Lawsuit Success',
  'writeoff.success-message': 'Writeoff Success. Please wait for Approval',
  'payment.success-message': 'Payment Success',
  'withdraw.success-message': 'Withdraw Success',
  'withdraw.maxamount': 'Amount (Max. {maxAmount} baht)',
  'closeloan.success-message': 'Close Loan Success',
  'delete-transaction.success-message': 'Delete Transaction Success',
  'options.effective': 'Effective',
  'options.flat': 'Flat',
  'rollback-transaction.success-message': 'Rollback Transaction Success',
  'loan-edit.show-rollback-transaction': 'Show Rollback Transactions',

  'adj.helmet-title': 'Statement Cutoff',
  'acc.helmet-title': 'Account Cutoff',
  'adj.page-title': 'Loan',
  'acc.page-title': 'Loan',
  'adj.success-message': 'Statement Cutoff Success',
  'acc.success-message': 'Account Cutoff Success',

  'statementCsv.helmet-title': 'Print Statement CSV',
  'statementPdf.helmet-title': 'Print Statement PDF',
  'statement.helmet-title': 'Print Statement',
  'statement.page-title': 'Loan',
  'statement.success-message': 'Print Statement Success',

  'requestapproval.approve.success-message': 'Approve Request Approval Success',
  'requestapproval.reject.success-message': 'Reject Request Approval Success',

  'product-new.helmet-title': 'Add Product',
  'product-new.page-title': 'Add Product',
  'product-new.success-message': 'Add Product Success',

  'product-edit.helmet-title': 'Product | {id}',
  'product-edit.page-title': 'Product',
  'product-edit.success-message': 'Update Product Success',

  'user-new.helmet-title': 'Add User',
  'user-new.page-title': 'Add User',
  'user-new.success-message': 'Add User Success',

  'user-edit.helmet-title': 'User | {email}',
  'user-edit.page-title': 'User',
  'user-edit.success-message': 'Update User Success',

  'withdraws-list.transfer': 'Transfer',
  'withdraws-list.cimb.transfer': 'Transfer CIMB',
  'withdraws-list.do-you-want-to-cancel':
    'Are you sure yon want to cancel this withdraw transaction?',

  // sms notify approval page
  'notify-approval.page-title': 'SMS to notify loan approval',
  'notify-approval-types.approve': 'Notify Loan Approval',
  'notify-approval-types.reject': 'Notify Loan Reject',
  'notify-approval.success-message': 'Send Sms Success',
  'notify-approval.search-success': 'Search Sms Success',
  'notify-approval.search-button': 'Send Sms',
  'sms-loan-close.search-success': 'Search Loans Success',
  'sms-loan-close.success-message': 'Send Sms Success',

  // sms notify cash page
  'notify-cash.page-title': 'SMS to notify cash in and cash out',
  'notify-approval-types.cashin': ' Notify Cash In',
  'notify-approval-types.cashout': 'Notify Cash Out',

  // project notify cash page
  'projects-create.success-message': 'Create Project Success',
  'projects-edit.success-message': 'Edit Project Success',

  // UI Label
  next: 'Next',
  previous: 'Previous',
  confirm: 'Confirm',
  'resend-otp': 'Resend Otp',
  'change-password': 'Change Password',
  logout: 'Logout',
  yes: 'Yes',
  no: 'No',
  submit: 'Submit',
  'full-name': 'Full Name',
  reject: 'Reject',
  reprocessing: 'Reprocessing',
  changestatus: 'Change Status',
  save: 'Save',
  cancel: 'Cancel',
  download: 'Download',
  downloadAll: 'Download All',
  yearOfStay: 'Year of stay',
  year: 'Year',
  month: 'Month',
  prepaid: 'Prepaid',
  postpaid: 'Postpaid',
  useCurrentAddress: 'Use Current Address',
  editloan: 'Edit Loan',
  principal: 'Principal',
  interest: 'Interest',
  fee: 'Fee',
  osb: 'Outstanding Balance',
  osbAt: 'OutStading Balance at {asOfDdate}',
  closeloan: 'Close Loan',
  calculateCloseLoan: 'Calculate Balance Close Loan',
  transaction: 'Transaction',
  add: 'Add',
  sendAgain: 'Send Otp Again',
  resetPassword: 'Reset Password',
  newPassword: 'New Password',
  confirmNewPassword: 'Confirm New Password',
  transferDate: 'Transfer Date',

  // Field Name
  firstName: 'First Name',
  lastName: 'Last Name',
  mobileNo: 'Mobile No',
  email: 'Email',
  password: 'Password',
  registerId: 'Registered Id',
  address: 'Address',
  subDistrict: 'Sub District',
  district: 'District',
  province: 'Province',
  postCode: 'Post Code',
  saleCode: 'Sale Code',
  title: 'Title',
  titleEn: 'Title (En)',
  firstNameEn: 'First Name (En)',
  lastNameEn: 'Last Name (En)',
  citizenId: 'Citizen Id',
  birthDate: 'Date of Birth',
  product: 'Product',
  requestAmount: 'Request Amount',
  installTerm: 'Installment Term (month)',
  installAmount: 'Installment Amount',
  status: 'Status',
  ncb: 'Ncb',
  document: 'Document',
  loanApproval: 'Loan Approval',
  general: 'General',
  employment: 'Employment',
  contactPerson: 'Contact Person',
  guarantor: 'Guarantor',
  guarantor1: 'Guarantor 1',
  guarantor2: 'Guarantor 2',
  personalInfo: 'Personal Info',
  loanInfo: 'Loan Info',
  accountInfo: 'Account Info',
  approvalInfo: 'Approval Info',
  statementDate: 'Statement Date',
  closeDate: 'Close Date',
  dueDate: 'Due Date',
  bankName: 'Bank',
  branchName: 'Branch',
  accountNo: 'Account No',
  accountName: 'Account Name',
  accountType: 'Account Type',
  maritalStatus: 'Marital Status',
  spouseInfo: 'Spouse Info',
  company: 'Company',
  occupation: 'Occupation',
  position: 'Position',
  salary: 'Salary',
  fileName: 'File Name',
  description: 'Description',
  category: 'Category',
  createDate: 'Created Date',
  lastModifyDate: 'Last Modified',
  lastModifyBy: 'Last Modified By',
  analystComment: 'Analyst Comment',
  analystAmount: 'Analyst Amount',
  approveAmount: 'Approve Amount',
  rejectReason: 'Rejected Reason',
  rejectDate: 'Rejected Date',
  openDate: 'Opened Date',
  firstDisburseAmount: 'First Disburse Amount',
  mobilePackage: 'Mobile Package',
  residentType: 'Resident Type',
  residentOwner: 'Resident Owner',
  rent: 'Rent',
  phoneNo: 'Phone No',
  phoneNoExt: 'Extension',
  contactTime: 'Contact Time',
  statementAddress: 'Statement Address',
  role: 'Role',
  organisationName: 'Organisation Name',

  'personalInfo.education': 'Education',
  'personalInfo.startWorkDate': 'Start Work Date',
  'personalInfo.totalLegalLoan': 'Legal Loan Count',
  'personalInfo.totalLegalLoanAmount': 'Legal Loan Amount',
  'personalInfo.totalIllegalLoan': 'Illegal Loan Count',
  'personalInfo.totalIllegalLoanAmount': 'Illegal Loan Amount',
  'addressInfo.current': 'Current Address',
  'addressInfo.permanent': 'Permanent Address',
  'addressInfo.company': 'Company Address',
  'addressInfo.company.name': 'Company Name',
  'addressInfo.company.isSubcontract': 'Is Subcontract',
  'addressInfo.company.subcontractPhoneNo': 'Subcontract Phone No',
  'addressInfo.company.hrPhoneNo': 'HR Phone No',
  'addressInfo.company.hrPhoneNoExt': 'Extension',
  'addressInfo.company.deptPhoneNo': 'Dept Phone No',
  'addressInfo.company.deptPhoneNoExt': 'Extention',
  'employmentInfo.employmentStatus': 'Employment Status',
  'employmentInfo.serviceYear': 'Experience(year)',
  'employmentInfo.serviceMonth': 'Experience(month)',
  'employmentInfo.position': 'Position',
  'employmentInfo.department': 'Department',
  'employmentInfo.baseSalary': 'Base Salary(baht)',
  'employmentInfo.extraIncome': 'Extra Income(baht)',
  'employmentInfo.extraIncomeSource': 'Extra Income Source',
  'employmentInfo.salaryChannel': 'Salary Channel',
  'employmentInfo.salaryFrequency': 'Salary Frequency(time)',
  'employmentInfo.salaryDate': 'Salary Date',
  'employmentInfo.occupationType': 'Occupation Type',
  'employmentInfo.businessType': 'Business Type',
  'employmentInfo.employeeId': 'Employee Id',
  'employmentInfo.nano.title': 'Business',
  'employmentInfo.nano.companyName': 'Company Name',
  'employmentInfo.nano.businessType': 'Business Type',
  'employmentInfo.nano.hrPhoneNo': 'HR Phone No',
  'employmentInfo.nano.hrPhoneNoExt': 'Extension',
  'employmentInfo.nano.startWorkDate': 'Start Work Date',
  'employmentInfo.nano.totalIncomeNano': 'Total Income Nano',
  'employmentInfo.nano.materialExpense': 'Material Expense',
  'employmentInfo.nano.wagesExpense': 'Wages Expense',
  'employmentInfo.nano.rentalExpense': 'Rental Expense',
  'employmentInfo.nano.waterExpense': 'Water Expense',
  'employmentInfo.nano.electricityExpense': 'Rlectricity Expense',
  'employmentInfo.nano.otherNanoExpense': 'OtherNano Expense',
  'employmentInfo.nano.oneMonth': 'First Month',
  'employmentInfo.nano.twoMonth': 'Second Month',
  'employmentInfo.nano.threeMonth': 'Third Month',
  'employmentInfo.nano.fourMonth': 'Fourth Month',
  'employmentInfo.nano.fiveMonth': 'Fifth Month',
  'employmentInfo.nano.sixMonth': 'Sixth Month',
  'employmentInfo.nano.profit': 'Profit',
  'employmentInfo.nano.extraIncome': 'Extra Income',
  'employmentInfo.nano.extraIncomeSource': 'Extra Income Source',
  'employmentInfo.nano.ExpensesPerMonth': 'Expenses Per Month',
  'employmentInfo.nano.position': 'Position',
  nanofinance: 'Nano Finance',
  loanType: 'Loan Type',
  paymentType: 'Payment Type',
  intType: 'Interest Type',
  intRate: 'Interest Rate(%)',
  minPayRate: 'Minimum Payment Rate(%)',
  minPayAmount: 'Minimum Payment Amount(baht)',
  lateFee: 'Late Fee(baht)',
  stampFee: 'Stamp Fee(%)',
  bureauFee: 'Bureau Hit Fee(baht)',
  bureauMissFee: 'Bureau Miss Fee(baht)',
  preterminateFee: 'Preterminate Fee(baht)',
  preterminatePeriod: 'Preterminate Period(day)',
  transferFee: 'Transfer Fee(baht)',
  interBankTransferFee: 'Interbank transfer Fee(baht)',
  safeMargin: 'Safe Margin(baht)',
  safeMarginDescription:
    'Note: If payment received differs from minimum payment due less than this margin, late fee will not be applied.',
  openingFee: 'Opening Fee(baht)',
  sendEStatement: 'Send e-statement.',
  includeInBotReport: 'Include loan in BOT report?',
  includeInNcbReport: 'Include loan in NCB report?',

  customId: 'Custom Id',
  creditLimit: 'Credit Limit',
  transDate: 'Transaction Date',
  trc: 'Trc',
  duration: 'Duration',
  cashIn: 'Cash In',
  cashOut: 'Cash Out',
  cfPrincipal: 'Principal',
  cfInterest: 'Interest',
  cfFee: 'Fee',
  newCfPrincipal: 'NewPrincipal',
  newCfInterest: 'NewInterest',
  newCfFee: 'NewFee',
  currentOsb: 'Current Osb',
  minDue: 'Min Due',
  minPaid: 'Min Paid',
  bankCode: 'Bank Code',
  writeoffAmount: 'Writeoff Amount',
  lastDueDate: 'Last Due Date',
  lastStatementDate: 'Last Statement Date',
  lastpayDate: 'Last Payment Date',
  lastpayAmount: 'Last Payment Amount',
  'Remaining Withdraw Amount': 'Remaining Withdraw Amount',
  'Last 8 period pastdue': 'Last 8 period pastdue',
  effectiveDate: 'Effective Date',
  filingDate: 'Filing Date',
  blackCaseNo: 'Black Case No.',
  redCaseNo: 'Red Case No',
  payWithRestructure: 'First payment received when restructure',
  judgementDate: 'Judgement Date',
  restructureDate: 'Restructure Date',
  type: 'Type',
  stampFeePayer: 'Stamp FeePayer',
  chargeStampFeeForCopy: 'ChargeStampFee For Copy',
  gracePeriod: 'gracePeriod',
  useGracePeriod: 'useGracePeriod',
  extra: 'Extra',
  extra1: 'Extra1',
  extra2: 'Extra2',
  extra3: 'Extra3',
  extra4: 'Extra4',
  extra5: 'Extra5',
  maximumCreditLimit: 'Maximum CreditLimit',
  totalMindue: 'Total Mindue',

  // Field Name that might be duplicate in various resource
  'organisation.name': 'Company Name',
  'product.name': 'Product Name',
  'application.id': 'Application Id',
  'loan.id': 'Loan Id',
  'transaction.id': 'Trans Id',
  'product.id': 'Product Id',

  // Custom validator validate message
  'validatemsg.incorrect-format': `{name} is not a valid {format} number`,
  'validatemsg.format.tel-no': 'telephone number',
  'validatemsg.format.register-id': 'registered id',
  'validatemsg.format.citizen-id': 'citizen id',

  'validatemsg.confirmpass-mismatch': 'Password and confirm password does not match',

  'validatemsg.inputbaht.max-value-exceed': '{name} max values is {maxValue}',
  'validatemsg.inputbaht.min-value-exceed': '{name} min values is {minValue}',

  // Enum
  // Payment Type
  'paymentType.revolving': 'Revolving',
  'paymentType.installment': 'Installment',

  // Interest Type
  'intType.effective': 'Effective Rate',
  'intType.flat': 'Flat Rate',

  // Loan Type
  'loanType.nano': 'Nano Finance',
  'loanType.ploan': 'Personal Loan',
  'loanType.staff': 'Staff Loan',
  'loanType.undefined': 'undefined',
  // Account Type
  'accountType.saving': 'Saving',
  'accountType.current': 'Current',
  'accountType.fixed': 'Fixed',
  // Marital status
  'maritalStatus.single': 'Single',
  'maritalStatus.married': 'Married',
  'maritalStatus.notregister': 'Married (Not Register)',
  'maritalStatus.divorced': 'Divorced',
  'maritalStatus.widow': 'Widow',
  // Bank
  'bank.BBL': 'BANGKOK BANK PUBLIC COMPANY LIMITED (BBL)',
  'bank.KBANK': 'KASIKORNBANK PUBLIC COMPANY LIMITED (KBANK)',
  'bank.RBS': 'THE ROYAL BANK OF SCOTLAND N.V. (RBS) BANGKOK BRANCH',
  'bank.KTB': 'KRUNG THAI BANK PUBLIC COMPANY LIMITED (KTB)',
  'bank.JPMC': 'JPMORGAN CHASE BANK, BANGKOK BRANCH (JPMC)',
  'bank.UFJ': 'THE BANK OF TOKYO-MITSUBISHI UFJ, LIMITED',
  'bank.TMB': 'TMB BANK PUBLIC COMPANY LIMITED (TMB)',
  'bank.SCB': 'SIAM COMMERCIAL BANK PUBLIC COMPANY LIMITED (SCB)',
  'bank.CITI': 'CITIBANK, N.A. (CITI) , BANGKOK BRANCH',
  'bank.SMBC': 'SUMITOMO MITSUI BANKING CORPORATION (SMBC)',
  'bank.SCBT': 'STANDARD CHARTERED BANK (THAI) PUBLIC COMPANY LIMITED (SCBT)',
  'bank.CIMBT': 'CIMB THAI BANK PUBLIC COMPANY LIMITED (CIMBT)',
  'bank.UOBT': 'UNITED OVERSEAS BANK (THAI) PUBLIC COMPANY LIMITED (UOBT)',
  'bank.BAY': 'BANK OF AYUDHYA PUBLIC COMPANY LIMITED (BAY)',
  'bank.MEGA ICBC': 'MEGA INTERNATIONAL COMMERCIAL BANK PUBLIC COMPANY LIMITED (MEGA ICBC)',
  'bank.AMERICA': 'BANK OF AMERICA NATIONAL ASSOCIATION (AMERICA)',
  'bank.GSB': 'GOVERNMENT SAVINGS BANK (GSB)',
  'bank.HSBC': 'THE HONGKONG AND SHANGHAI BANKING CORPORATION LIMITED (HSBC)',
  'bank.DB': 'DEUTSCHE BANK AKTIENGESELLSCHAFT (DB)',
  'bank.GHB': 'GOVERNMENT HOUSING BANK (GHB)',
  'bank.BAAC': 'BANK FOR AGRICULTURE AND AGRICULTURAL COOPERATIVES (BAAC)',
  'bank.MHCB': 'MIZUHO CORPORATE BANK LIMITED (MHCB)',
  'bank.BNPP': 'BNP PARIBAS, BANGKOK BRANCH (BNPP)',
  'bank.TBANK': 'THANACHART BANK PUBLIC COMPANY LIMITED (TBANK)',
  'bank.ISBT': 'ISLAMIC BANK OF THAILAND (ISBT)',
  'bank.TISCO': 'TISCO BANK PUBLIC COMPANY LIMITED (TISCO)',
  'bank.KK': 'KIATNAKIN BANK PUBLIC COMPANY LIMITED (KK)',
  'bank.ICBC THAI':
    'INDUSTRIAL AND COMMERCIAL BANK OF CHINA (THAI) PUBLIC COMPANY LIMITED (ICBC THAI)',
  'bank.TCRB': 'THAI CREDIT RETAIL BANK PUBLIC COMPANY LIMITED (TCRB)',
  'bank.LH BANK': 'LAND AND HOUSES RETAIL BANK PUBLIC COMPANY LIMITED (LH BANK)',
  'bank.TTB': 'TTB BANK PUBLIC COMPANY LIMITED (TTB)',
  // Restructure
  'restructureType.debtRestructuring': 'Debt Restructuring',
  'restructureType.sam': 'SAM',
  'restructureType.settlementAgreement': 'Settlement Agreement',
  'restructureType.defaultJudgement': 'Default Judgement',
  'restructure.success-message': 'Restructure Success. Please wait for Approval',
  // Filing
  'filing.success-message': 'Filing Success. Please wait for Approval',

  relation: 'Relation',
  'relation.relative': 'Relative',
  'relation.spouse': 'Spouse',
  'relation.parents': 'Parent',
  'relation.child': 'Child',
  'relation.sibling': 'Sibling',
  'relation.friend': 'Friend',
  'relation.colleague': 'Colleague',

  'title.mr': 'Mr',
  'title.mrs': 'Mrs',
  'title.miss': 'Miss',

  'documentCategory.application': 'Application',
  'documentCategory.citizenCard': 'Citizen Card',
  'documentCategory.incomeStatement': 'Income Statement',
  'documentCategory.consent': 'Consent',
  'documentCategory.letterOfGuarantee': 'Letter of Guarantee',
  'documentCategory.increaseCreditLimitDocuments': 'Increase Credit Limit Documents',
  'documentCategory.disburseDocuments': 'Disburse Documents',
  'documentCategory.bankbook': 'Bank Book',
  'documentCategory.signature': 'Signature',
  'documentCategory.other': 'Other',

  'residentType.house': 'House',
  'residentType.townHouse': 'Town House',
  'residentType.condo': 'Condominium',
  'residentType.apartment': 'Apartment',

  'residentOwner.own': 'Me',
  'residentOwner.family': 'Family',
  'residentOwner.welfare': 'Welfare House',
  'residentOwner.rent': 'Rental House',

  'education.underHighSchool': '< High School',
  'education.highSchool': ' High School / Vocational',
  'education.diploma': 'Diploma / High Vocational',
  'education.bachelor': 'Bachelor Degree',
  'education.higherBachelor': '> Bachelor Degree',

  'employmentStatus.permanent': 'Permanent',
  'employmentStatus.partTime': 'Part Time',
  'employmentStatus.daily': 'Daily',

  'salaryChannel.bankTransfer': 'Bank Transfer',
  'salaryChannel.cheque': 'Cheque',
  'salaryChannel.cash': 'Cash',

  'stampFeePayer.borrower': 'borrower',
  'stampFeePayer.lender': 'lender',

  lawsuit: 'Lawsuit',
};

export default {
  locale: 'en-US',
  antdData,
  messages,
};
